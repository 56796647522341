import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { Icon, Dropdown, Menu, Skeleton, Row, Col, Card, Button, Select } from 'antd';
import { gql } from 'apollo-boost';
import Debug from 'debug';
import _ from 'lodash';
import './App.css';
import { injectIntl } from 'react-intl';
const gelleryView = require('./images/gallery-view.svg');
const { Option } = Select;

const debug = Debug('shgbit:space');

@withRouter
@inject('appStore')
@observer
class App extends Component {
	get store() { return this.props.appStore; }
	get currentCompany() { return this.store.currentCompany; }
	get intl() { return this.props.intl; }
	@observable data = null;
	@observable blogs = [];
	@observable langtext = this.intl.formatMessage({ id: 'common.english' });
	@observable langLabel = this.store.lang;

	async componentDidMount() {
		await this.load();
		if (this.data.me.lang === 'zh' || this.data.me.lang === 'zh-CN') {
			this.langtext = this.intl.formatMessage({ id: 'common.english' });
		} else if (this.data.me.lang === 'en' || this.data.me.lang === 'en-US') {
			this.langtext = this.intl.formatMessage({ id: 'common.chinese' });
		}
		console.log('this.blogs', this.blogs);
		if (this.blogs && this.blogs.blogs.length > 0) {

			_.map(this.blogs.blogs, x => {
				if (x.images && x.images.length > 0) {
					x.images = this.store.uploadBaseUrl + '/files' + x.images[0];
				} else {
					x.images = '';
				}
			});
		}
	}

	async load() {
		this.data = await this.store.query(gql`
				{
					me {
						id
						name
						lang
						avatar
						company {
							code
							id
							space { 
								cover 
							}
						}
						apps {
							code
							name
							alias
							icon
							callback {
								mobile
								desktop
							}
						}
					}
				
				}
		`);

		this.blogs = await this.store.query(gql`
				{
					blogs(company:"${this.data.me.company.id}"){
							id
							title
							content
							summary
							images
							createdAt
					}
				}
		`);
	}

	logout = async () => {
		this.store.logout();
	}

	langSwitch = async (value) => {
		console.log(`selected ${value}`);
		this.langLabel = value;
		await this.store.transportLayer.lang({ userid: this.data.me.id, lang: value });
		location.reload(true);
	}

	langChange = async () => {
		let lang = 'en-US';
		if (this.langtext === this.intl.formatMessage({ id: 'common.english' })) { lang = 'en-US'; }
		if (this.langtext === this.intl.formatMessage({ id: 'common.chinese' })) { lang = 'zh-CN'; }
		await this.store.transportLayer.lang({ userid: this.data.me.id, lang: lang });
		location.reload(true);
	}

	profile = async () => {
		this.store.profile();
	}

	getCallbackByUserAgent = (app) => {
		return /mobile/i.test(navigator.userAgent) ? app.callback.mobile : app.callback.desktop;
	}

	getAppsByUserAgent = (apps) => {
		let result = [];
		if (/mobile/i.test(navigator.userAgent)) {
			result = _.filter(apps, x => x.callback.mobile);
		} else {
			result = _.filter(apps, x => x.callback.desktop);
		}
		return result;
	}

	onClickApp = async (callback) => {
		debug(callback);
		window.location = `${callback}?token=${this.store.token}`;
	}

	onClickBlog = async id => {
		let app = _.filter(this.getAppsByUserAgent(this.data.me.apps, x => x.alias === 'blog'));
		if (app.length > 0) {
			let callback = '';
			app.forEach(each => {
				let callstr = this.getCallbackByUserAgent(each);
				if (callstr.includes('blog')) {
					callback = callstr;
				}
			});
			if (callback && callback !== '') {
				window.location = `${callback}/${id}/view?token=${this.store.token}`;
			}
		}
	}

	render() {
		if (!this.data) { return <Skeleton title={true} paragraph={{ rows: 7 }} />; }
		const profile = this.intl.formatMessage({ id: 'profile' });
		const logout = this.intl.formatMessage({ id: 'logout' });
		const greeting1 = this.intl.formatMessage({ id: 'greeting1' });
		const greeting2 = this.intl.formatMessage({ id: 'greeting2' });
		const am = this.intl.formatMessage({ id: 'am' });
		const pm = this.intl.formatMessage({ id: 'pm' });
		const applications = this.intl.formatMessage({ id: 'applications' });
		const blogs = this.intl.formatMessage({ id: 'blogs' });
		const noTitle = this.intl.formatMessage({ id: 'common.noTitle' });
		const zhCN = this.intl.formatMessage({ id: 'common.zhCN' });
		const enUS = this.intl.formatMessage({ id: 'common.enUS' });
		const enGB = this.intl.formatMessage({ id: 'common.enGB' });
		return (
			<div className="container">
				<div className="header">
					<div className="header-left">
						<img src={gelleryView} alt="appStore" />
						<span>Space365</span>
					</div>
					{/* <div className="header-avator" style={{ backgroundImage: `url(${avator})` }}></div> */}
					<Dropdown overlay={
						<Menu>
							<Menu.Item onClick={() => this.profile()}><Icon type="safety" />{profile}</Menu.Item>
							<Menu.Item onClick={() => this.logout()}><Icon type="logout" />{logout}</Menu.Item>
						</Menu>
					}>
						<div style={{ padding: '0 16px', display: 'flex' }}>
							<Button style={{ marginRight: '20px', width: '80px' }} onClick={() => this.langChange()}>{this.langtext}</Button>
							{/* <Select defaultValue={this.langLabel} style={{ marginRight: '20px', width: '125px' }} onChange={this.langSwitch}>
								<Option value="en-US">{enUS}</Option>
								<Option value="en-GB">{enGB}</Option>
								<Option value="zh-CN">{zhCN}</Option>
							</Select> */}
							<div className="header-avator" style={{ backgroundImage: `url(${this.data.me.avatar})` }}></div>
						</div>
					</Dropdown>
				</div>
				{/* <div className="banner" style={{ backgroundImage: `url(${banner})` }}> */}
				<div className="banner" style={{ backgroundImage: `url(${this.data.me.company.space.cover})` }}>
					<h2>
						<div>{greeting1}{(moment().format('a') === 'am') ? am : pm}{greeting2}{this.data.me.name}</div>
					</h2>
				</div>
				<div className="app-section">
					<h3>{applications}</h3>
					<div className="app-wrapper">
						{
							_.map(this.getAppsByUserAgent(this.data.me.apps), each => {
								let callback = this.getCallbackByUserAgent(each);

								return (
									<div key={each.code} className="app-block" onClick={() => this.onClickApp(callback)}>
										<img src={each.icon} alt={each.name} />
										<p>{each.alias}</p>
									</div>
								);
							})
						}
					</div>
				</div>
				<div className="nortify-section">
					<h3>{blogs}</h3>
					<Row gutter={24}>
						{
							_.map(this.blogs.blogs, x => {
								return (
									<Col key={x.id} xs={24} sm={6} style={{ marginBottom: 16 }}>
										<Card
											hoverable
											style={{ width: '100%' }}
											// cover={<img alt="example" style={{ height: '40%' }} src={x.images} />}
											cover={<div style={{
												width: '100%',
												height: 160,
												backgroundImage: `url(${x.images})`,
												backgroundSize: 'cover',
												backgroundPositionX: 'center',
												backgroundPositionY: 'center',
												backgroundRepeat: 'no-repeat'
											}}>
											</div>}

											onClick={() => this.onClickBlog(x.id)}
										>
											{/* <img src={`${x.images})`} style={{
												width: '100%',
												height: 160,      
											}} /> */}
											<p style={{ fontSize: 16, fontWeight: 'bold', color: '#333', height: 72, marginTop: 12, marginBottom: 12 }}>
												{x.title ? x.title : noTitle}
											</p>
											<span style={{ fontSize: 12, color: '#ccc', margin: 0 }}>{moment.unix(x.createdAt).fromNow()}</span>
										</Card>
									</Col>
								);
							})
						}
					</Row>
				</div>
			</div>
		);
	}
}

export default injectIntl(App);