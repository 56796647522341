import Debug from 'debug';
import { observable, toJS } from 'mobx';
import OAuth from 'share/common/OAuth';
import TransportLayer from 'transport/TransportLayer';
import ApolloClient from 'apollo-boost';
import config from 'common/config';
import _ from 'lodash';
import { gql } from 'apollo-boost';

config.set('devWithCloudEnv', false);

const debug = Debug('shgbit:store');

export default class AppStore {
	routeArg = null;
	@observable loading = true;
	get uploadBaseUrl() {
		return config.getBackendOrigin();
	}

	constructor() {
		this.apolloClient = this.createApolloClient();
	}

	async init() {
		this.oauth = new OAuth({
			clientId: '4bCT6QS-G'
		});
		this.token = await this.oauth.token();
		this.transportLayer = new TransportLayer(this.token);

		this.lang = await this.oauth.lang();
		this.loading = false;
	}

	async query(query, variables) {
		let result = await this.apolloClient.query({ query, variables, fetchPolicy: 'no-cache' });
		return result.data;
	}

	async setCompany() {
		let c = _.clone(this.currentCompany);
		this.currentCompany = await this.transportLayer.getCompanyById(c.id);
		this.currentCompany.id = c.id;
		this.currentCompany.members = c.members;
		this.currentCompany.rooms = c.rooms;
	}

	createApolloClient() {
		let result = new ApolloClient({
			uri: `${config.getBackendOrigin()}/graphql`,
			request: async (operation) => {
				operation.setContext({
					headers: {
						Authorization: this.token ? `Bearer ${this.token}` : ''
					}
				});
			},
		});
		return result;
	}

	pushRouteArg(arg) {
		this.routeArg = arg;
	}

	popRouteArg(arg) {
		let result = this.routeArg;
		this.routeArg = null;
		return result;
	}

	logout() {
		this.oauth.logout();
		this.currentUser = null;
		this.currentCompany = null;
	}

	profile() {
		this.oauth.profile();
	}
}