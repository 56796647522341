import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'mobx-react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import AppStore from '@/AppStore';
import pkg from '../package.json';
import { LocaleProvider } from 'antd';
import { IntlProvider, addLocaleData } from 'react-intl';
import enGBMessages from 'share/locales/en-GB/translation.json';
import enUSMessages from 'share/locales/en-US/translation.json';
import zhMessages from 'share/locales/zh-CN/translation.json';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/en-gb';
// import enUS from 'antd-mobile/lib/locale-provider/en_US';
// import enGB from 'antd-mobile/lib/locale-provider/en_GB';
// import zhCN from 'antd-mobile/lib/locale-provider/zh_CN';
import zh from 'react-intl/locale-data/zh';
import en from 'react-intl/locale-data/en';
addLocaleData([...en, ...zh]);

let messages = {
	'en': enUSMessages,
	'en-US': enUSMessages,
	'en-GB': enGBMessages,
	'zh': zhMessages,
	'zh-CN': zhMessages
};

localStorage.debug = 'shgbit:*';

let appStore = new AppStore();

appStore.init().then(() => {
	moment.locale(appStore.lang);
	let app = (
		<Provider appStore={appStore}>
			<IntlProvider locale={appStore.lang} messages={messages[appStore.lang]}>
				<LocaleProvider locale={appStore.lang}>
					<BrowserRouter basename={pkg.homepage}>
						<Switch>
							<Route path="/" component={App} />
						</Switch>
					</BrowserRouter>
				</LocaleProvider>
			</IntlProvider>
		</Provider>
	);
	ReactDOM.render(app, document.getElementById('root'));
	serviceWorker.unregister();
});